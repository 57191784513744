<template>
  <div id="complaint">
    <div class="header">投诉</div>
    <div v-if="isShow" class="complaint-content scale-up-ver-top">
      <el-form
        ref="complaintForm"
        :model="complaintForm"
        :rules="rules"
        label-width="170px"
        class="demo-complaintForm"
      >
        <el-row class="my-row my-row1">
          <el-col :span="12">
            <el-form-item label="投诉人实名认证">
              <el-button
                style="float: left"
                type="primary"
                @click="handleRead()"
                >读取身份证</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="12">
            <el-form-item label="投诉人姓名" prop="complaintUser">
              <el-input
                v-model="complaintForm.complaintUser"
                class="my-input-new"
                placeholder="读取身份证后显示"
                clearable
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="complaintId">
              <el-input
                v-model="complaintForm.complaintId"
                class="my-input-new"
                placeholder="读取身份证后显示"
                clearable
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="12">
            <el-form-item label="工作单位" prop="complaintUnit">
              <el-input
                v-model="complaintForm.complaintUnit"
                class="my-input-new"
                placeholder="请输入工作单位"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="统一社会信用代码" prop="complaintUnitCode">
              <el-input
                v-model="complaintForm.complaintUnitCode"
                class="my-input-new"
                placeholder="请输入统一社会信用代码"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="24">
            <el-form-item label="联系方式" prop="phone">
              <el-input
                v-model="complaintForm.phone"
                class="my-input-new"
                placeholder="请输入联系方式"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="24">
            <el-form-item label="投诉内容" prop="content">
              <el-input
                v-model="complaintForm.content"
                class="my-input-new"
                placeholder="请输入投诉内容"
                type="textarea"
                rows="2"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="24">
            <el-form-item label="投诉事项基本事实" prop="fact">
              <el-input
                v-model="complaintForm.fact"
                class="my-input-new"
                placeholder="请输入投诉事项基本事实"
                type="textarea"
                rows="2"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="24">
            <el-form-item label="相关请求及主张" prop="appeal">
              <el-input
                v-model="complaintForm.appeal"
                class="my-input-new"
                placeholder="请输入相关请求及主张"
                type="textarea"
                rows="2"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding-bottom: 20px">
          <el-table
            :data="enclosure"
            border
            style="width: 100%"
            class="my-tabel"
          >
            <!-- <el-table-column
              type="index"
              label="序号"
              align="center"
              width="60"
            > 
            </el-table-column>-->
            <el-table-column
              prop="fileExName"
              label="电子件名称"
              align="center"
            >
              <template slot-scope="{ row }">
                <span
                  >{{ row.attachmentName }}
                  <span style="color: green" v-if="row.fileId">(已签章)</span>
                  <span style="color: red" v-else>(未签章)</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="电子件" align="center">
              <template slot-scope="{ row }">
                <el-button type="text">{{ row.attachmentFileUrl }}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <el-button
                  @click="handlePhotoUpload(row)"
                  v-if="!row.attachmentFileUrl"
                  icon="el-icon-upload2"
                  type="primary"
                  >上传</el-button
                >
                <el-button v-else type="danger" @click="handleDelete(row)"
                  >删除</el-button
                >
                <el-button
                  @click="handleSignature(row)"
                  v-if="row.attachmentFileUrl"
                  type="primary"
                  >签章</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="12">
            <el-form-item label="手机号码" prop="verificationPhone">
              <el-input
                v-model="complaintForm.verificationPhone"
                style="width: 240px; float: left"
                placeholder="请输入手机号码"
                clearable
              ></el-input>
              <el-button
                size="mini"
                :disabled="isDis"
                style="float: left; margin-left: 6%; margin-top: 6px"
                type="success"
                @click="handleSend"
              >
                {{ buttonText }}
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请输入验证码" prop="verificationCode">
              <el-input
                v-model="complaintForm.verificationCode"
                class="my-input-new"
                placeholder="请输入手机验证码"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="btn-box">
        <el-button type="primary" @click="handleConfirmComplaint()"
          >确认投诉</el-button
        >
        <el-button @click="handleCancel()">取消投诉</el-button>
      </div>
    </div>

    <div v-if="isLook" class="complaint-content scale-up-ver-top">
      <el-form
        ref="complaintViewForm"
        :model="complaintViewForm"
        label-width="170px"
        class="demo-complaintForm"
      >
        <el-row class="my-row my-row1">
          <el-col :span="12">
            <el-form-item label="投诉处理状态">
              <el-input
                v-model="complaintViewForm.statusName"
                class="my-input-new"
                placeholder="投诉处理状态"
                clearable
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="处理时间">
              <el-input
                v-model="complaintViewForm.auditTime"
                class="my-input-new"
                placeholder="处理时间"
                clearable
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="12">
            <el-form-item label="投诉人姓名" prop="complaintUser">
              <el-input
                v-model="complaintViewForm.complaintUser"
                class="my-input-new"
                placeholder="投诉人姓名"
                clearable
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作单位" prop="complaintUnit">
              <el-input
                v-model="complaintViewForm.complaintUnit"
                class="my-input-new"
                placeholder="请输入工作单位"
                clearable
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="身份证号" prop="complaintId">
              <el-input
                v-model="complaintForm.complaintId"
                class="my-input-new"
                placeholder="读取身份证后显示"
                clearable
                disabled
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
        <!-- <el-row class="my-row my-row1">
         
          <el-col :span="12">
            <el-form-item label="统一社会信用代码" prop="complaintUnitCode">
              <el-input
                v-model="complaintForm.complaintUnitCode"
                class="my-input-new"
                placeholder="请输入统一社会信用代码"
                clearable
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <!-- <el-row class="my-row my-row1">
          <el-col :span="24">
            <el-form-item label="联系方式" prop="phone">
              <el-input
                v-model="complaintForm.phone"
                class="my-input-new"
                placeholder="请输入联系方式"
                clearable
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row class="my-row my-row1">
          <el-col :span="24">
            <el-form-item label="投诉内容" prop="content">
              <el-input
                v-model="complaintViewForm.content"
                class="my-input-new"
                placeholder="请输入投诉内容"
                type="textarea"
                rows="2"
                disabled
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="24">
            <el-form-item label="投诉事项基本事实" prop="fact">
              <el-input
                v-model="complaintViewForm.fact"
                class="my-input-new"
                placeholder="请输入投诉事项基本事实"
                type="textarea"
                disabled
                rows="2"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="24">
            <el-form-item label="相关请求及主张" prop="appeal">
              <el-input
                v-model="complaintViewForm.appeal"
                class="my-input-new"
                placeholder="请输入相关请求及主张"
                type="textarea"
                disabled
                rows="2"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="my-row my-row1">
          <el-col :span="24">
            <el-form-item label="处理意见" prop="appeal">
              <el-input
                v-model="complaintViewForm.suggestions"
                class="my-input-new"
                placeholder="请输入处理意见"
                type="textarea"
                disabled
                rows="2"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row style="padding-bottom: 20px">
          <el-table
            :data="enclosure"
            border
            style="width: 100%"
            class="my-tabel"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column
              prop="fileExName"
              label="电子件名称"
              align="center"
            >
              <template slot-scope="{ row }">
                <span
                  >{{ row.attachmentName }}
                  <span style="color: green" v-if="row.fileId">(已签章)</span>
                  <span style="color: red" v-else>(未签章)</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="电子件列表" align="center">
              <template slot-scope="{ row }">
                <el-button type="text">{{ row.attachmentFileUrl }}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="{ row }">
                <el-button
                  @click="handlePhotoUpload(row)"
                  v-if="!row.attachmentFileUrl"
                  icon="el-icon-upload2"
                  type="primary"
                  >上传</el-button
                >
                <el-button v-else type="danger" @click="handleDelete(row)"
                  >删除</el-button
                >
                <el-button
                  @click="handleSignature(row)"
                  v-if="row.attachmentFileUrl"
                  type="primary"
                  >签章</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row> -->
        <!-- <el-row class="my-row my-row1">
          <el-col :span="12">
            <el-form-item label="手机号码" prop="verificationPhone">
              <el-input
                v-model="complaintForm.verificationPhone"
                style="width: 240px; float: left"
                placeholder="请输入手机号码"
                clearable
              ></el-input>
              <el-button
                size="mini"
                :disabled="isDis"
                style="float: left; margin-left: 6%; margin-top: 6px"
                type="success"
                @click="handleSend"
              >
                {{ buttonText }}
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请输入验证码" prop="verificationCode">
              <el-input
                v-model="complaintForm.verificationCode"
                class="my-input-new"
                placeholder="请输入手机验证码"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>

      <div class="btn-box">
        <el-button @click="handleCancel()">返回</el-button>
      </div>
    </div>

    <div class="btn-box" v-if="!isShow && !isLook">
      <el-button  @click="handleViewComplsint()">投诉查看</el-button>
      <el-button type="danger" @click="handleComplsint()">我要投诉</el-button>
      
    </div>
    <UploadImgNewCard
      :ocr-data="ocrData"
      @successUpload="uploadSuccess"
      ref="uploadImgNewCard"
      :index="'1001'"
    ></UploadImgNewCard>
    <CerModuleDialog
      @cerAssignData="cerAssignData"
      ref="cerModuleDialog"
    ></CerModuleDialog>
    <LicenseQzDialog
      @successSavePdf="successSavePdf"
      ref="licenseQzDialog"
    ></LicenseQzDialog>
  </div>
</template>

<script>
import IDread from "@/utils/IDread";
import { getPhoneCode, sumbitComplaint,viewComplaint,booleanComplaint } from "@/api/formulaHomeApi";
export default {
  mixins: [IDread],
  data() {
    return {
      selectId: "",
      buttonText: "获取验证码",
      ocrData: {},
      isShow: false,
      isLook: false,
      isDis: false,
      enclosure: [
        {
          attachmentName: "证明材料",
          attachmentFileUrl:"",
          fileId: "",
          id: 1,
        },
      ],//  附件列表
      complaintForm: {
        complaintUser: "",
        complaintId: "",
        complaintUnit: "",
        complaintUnitCode: "",
        phone: "",
        content: "",
        fact: "",
        appeal: "",
        verificationPhone: "",
        verificationCode: "",
      },
      complaintViewForm:{

      },
      rules: {
        verificationPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
            message: "请输入正确的手机号",
          },
        ],
        verificationCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        complaintUser: [
          { required: true, message: "请读取投诉人姓名", trigger: "blur" },
        ],
        complaintId: [
          { required: true, message: "请读取身份证号", trigger: "blur" },
        ],
        complaintUnit: [
          { required: true, message: "请输入工作单位", trigger: "blur" },
        ],
        complaintUnitCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
          {
            pattern: /^(([0-9A-Za-z]{15})|([0-9A-Za-z]{18})|([0-9A-Za-z]{20}))$/,
            message: "请输入正确的统一社会信用代码",
          },
        ],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" },
                {
                 pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/,
                 message: "请输入正确的手机号",
                },
        ],
        content: [
          { required: true, message: "请输入投诉内容", trigger: "blur" },
        ],
        fact: [
          {
            required: true,
            message: "请输入投诉事项基本事实",
            trigger: "blur",
          },
        ],
        appeal: [
          { required: true, message: "请输入相关请求及主张", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 读取
    handleRead() {
      this.toConnect();
    },
    // 读取成功
    readSuccess({ name, idCard }) {
      this.complaintForm.complaintUser = name;
      this.complaintForm.complaintId = idCard;
    },
    // 获取验证码
    handleSend() {
      let pho = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (!pho.test(this.complaintForm.verificationPhone)) {
        console.log("不正确");
        this.$message.error("请输入正确手机号！");
        return;
      }
      getPhoneCode({ phone: this.complaintForm.verificationPhone }).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("发送成功！");
            let count = 60;
            this.time = count;
            this.isDis = true;
            this.timer = setInterval(() => {
              if (this.time > 0 && this.time <= count) {
                this.time--;
                this.buttonText = "重新发送" + this.time + "s";
              } else {
                this.isDis = false;
                this.buttonText = "发送验证码";
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      );
    },
    // 批量上传弹窗
    handlePhotoUpload({ id }) {
      this.selectId = id;
      this.$refs.uploadImgNewCard.handleUploadAll();
    },
    // 上传成功
    uploadSuccess(res) {
      console.log(res);
      if (res.code == 200) {
        this.$alert("上传成功!", "", { confirmButtonText: "确定" });
        this.enclosure.forEach((ele) => {
          if (ele.id == this.selectId) {
            ele.attachmentFileUrl = res.data.url;
          }
        });
      }
    },
    // 删除
    handleDelete({ id }) {
      this.$alert("确定删除附件", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            this.enclosure.forEach((ele) => {
              if (ele.id == id) {
                ele.attachmentFileUrl = "";
              }
            });
          }
        },
      });
    },

    // 签章
    handleSignature({ attachmentFileUrl, id }) {
      this.selectId = id;
      this.$refs.licenseQzDialog.getPdf(attachmentFileUrl);
    },
    // 签章成功
    successSavePdf(id) {
      console.log(id);
      this.enclosure.forEach((ele) => {
        if (ele.id == this.selectId) {
          ele.fileId = id;
        }
      });
      console.log("enclosure", this.enclosure);
    },
    handleComplsint() {
      // this.$refs.cerModuleDialog.handleCaInfo();

      const { code,platform} = this.$route.query;
      if(code!='performance' || platform == '2'){
        this.$message.error('暂未开放，敬请期待')
        return
      }
      this.hansleBooleanComplaint();

    },
    async hansleBooleanComplaint() {
      const { epointId,platform} = this.$route.query;
      let info = {
        linkId : epointId,
        platform : platform
      }
      const { data } = await booleanComplaint(info);
      if (data) {
        this.isShow = true;
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
       
      }else{
        this.$alert("目前已有投诉，正在处理", "", { confirmButtonText: "确定" });
      }
    },

  
    handleViewComplsint(){
      const { code,platform} = this.$route.query;
      if(code!='performance' || platform == '2'){
        this.$message.error('暂未开放，敬请期待')
        return
      }
      this.hansleView()
      
      // if(code!='performance' || platform == '2'){
      //   this.$message.error('暂未开放，敬请期待')
      // }else{
      //   this.$refs.cerModuleDialog.handleCaInfo('view');
      // }
    },
    // ca锁验证
    cerAssignData(info) {
      // this.isShow = true;
      // setTimeout(() => {
      //   this.scrollToBottom();
      // }, 100);
      this.$emit('propCaInfo', info);
    },
    scrollToBottom() {
      console.log("scrollToBottom");
      (function smoothscroll() {
        const currentScroll =
          document.documentElement.scrollTop || document.body.scrollTop; // 已经被卷掉的高度
        const clientHeight = document.documentElement.clientHeight; // 浏览器高度
        const scrollHeight = document.documentElement.scrollHeight; // 总高度
        if (scrollHeight - 10 > currentScroll + clientHeight) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(
            0,
            currentScroll + (scrollHeight - currentScroll - clientHeight) / 10
          );
        }
      })();
    },
    //确认投诉
    handleConfirmComplaint() {
      this.$refs["complaintForm"].validate((valid) => {
        if (valid) {
          let isQz = this.enclosure.some((val) => {
            return val.fileId;
          });
          if (!isQz) {
            this.$message.error("请先签章！");
            return;
          }
          const { code ,id,epointId,platform } = this.$route.query;
          if(code == 'performance'){
            this.complaintForm.linkId = epointId;
          }else{
            this.complaintForm.linkId = id;
          }
          this.complaintForm.platform = platform;
          this.complaintForm.code = code;
          this.complaintForm.proveFileId = this.enclosure[0].fileId; // 材料Id
          console.log("complaintForm", JSON.stringify(this.complaintForm) );
          this.hansleSave();
        }
      });
    },
    //提交投诉
    async hansleSave() {
      this.$emit('complaintProp',{loading:true,isSuccess:false})
      const { code } = await sumbitComplaint(this.complaintForm);
      if (code == 200) {
        this.$emit('complaintProp',{loading:false,isSuccess:true})
        // this.$message.success("提交成功！");
        this.$alert("投诉成功!请等待审核！", "", { confirmButtonText: "确定" });
        this.isShow = false
        this.complaintForm = {}
        this.enclosure = [
        {
          attachmentName: "证明材料",
          attachmentFileUrl:"",
          fileId: "",
          id: 1,
        },
      ]

      }
    },

    async hansleView() {
      const { epointId,platform} = this.$route.query;
      let info = {
        linkId : epointId,
        platform : platform
      }
      const { code,data } = await viewComplaint(info);
      if (code == 200) {
        this.complaintViewForm = data
        this.isLook = true;
      }else{
        this.$alert("暂无投诉", "", { confirmButtonText: "确定" });
      }
    },

    // 取消投诉
    handleCancel() {
      this.isShow = false;
      this.isLook = false;
      this.complaintForm = {}
      this.enclosure = [
        {
          attachmentName: "证明材料",
          attachmentFileUrl:"",
          fileId: "",
          id: 1,
        },
      ]
    },
  },
};
</script>

<style lang="scss" scoped>
#complaint {
  width: 100%;
  .header {
    background: #f9f9f9;
    font-size: 20px;
    border-bottom: 1px solid #9e9e9e40;
    text-align: left;
    font-weight: 700;
    line-height: 40px;
    padding: 17px 30px 20px;
    color: red;
  }
  .complaint-content {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
  }
  .btn-box {
    text-align: center;
    background: #fff;
    padding: 20px 10px;
  }
}
.scale-up-ver-top {
  -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-4-23 9:59:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
}
@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
}
</style>